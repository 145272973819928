.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
  font-family: "HalloWitchZ";
  src: url(./fonts/HalloWitchZ.ttf) format("truetype");
}

@font-face {
  font-family: "Semplicita Pro";
  src: url(./fonts/SemplicitaPro.otf) format("opentype");
}

@font-face {
  font-family: "Gang Avenue";
  src: url("./fonts/Gang Avenue.otf") format("opentype");
}

body {
  background: #1b1c1d;
}

#root .ui.menu {
  margin: 0;
}

#root .logo {
  font-size: 6.75rem;
  margin-top: -3rem;
}

#root .logo h1 {
  font-family: "Permanent Marker";
  font-size: 8rem;
  color: #b72b06;
  margin-top: -1.3rem;
  text-align: center;
}

#root .logo img {
  display: block;
  margin: 0 auto;
}

#root .slogan {
  font-family: "Love Ya Like A Sister";
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 10px;
  margin-top: -1rem;
}

#root h2 {
  color: #c1c1c1;
  margin-bottom: 1.5rem;
  font-size: 4rem;
}

#root .recentWitchings {
  margin-top: 2rem;
  font-size: 3rem;
  font-family: "New Tegomin";
}

#root h3 {
  color: #c1c1c1;
  font-size: 2rem;
  font-family: "New Tegomin";
}

.userName {
  margin: 0 0.3em;
  font-weight: bold;
}

#root .loginBtn {
  margin-right: 1rem;
  color: #7289da;
  box-shadow: 0 0 0 2px #7289da inset !important;
}

#root .loginBtn:hover {
  color: white;
  background-color: #7289da;
}

#root .loginBtn:active,
#root .loginBtn:focus {
  background-color: #5f73b8;
  color: white;
  box-shadow: 0 0 0 2px #5f73b8 inset !important;
}

.menu {
  padding: 1rem;
  /* margin: 0; */
}

.menuHello {
  font-size: 1.6rem;
  font-family: "Rubik";
}

#root .menu .witchingStat {
  line-height: 1.4rem;
  margin-top: 0.2rem;
}

.playerName {
  padding: 0 1.5rem 0 1rem;
  font-size: 2rem;
  /* font-family: "HalloWitchZ"; */
  /* line-height: 4rem; */
  /* font-weight: bold; */
}

.seasonDropdown {
  font-size: 1.35rem;
  font-weight: bold;
}

.witchingRow {
  display: flex;
}

.witchingRow a {
  color: #c1c1c1;
}

.witchingRow a:hover {
  color: white;
}

.emptyWitchings {
  font-size: 2rem;
  font-family: "New Tegomin";
  margin: 3rem 0;
}

#root .witcher {
  flex: 1 1 0;
  text-align: right;
  flex-direction: row-reverse;
}

.evidenceContainer {
  width: 40rem;
  height: 0;
  padding-top: 66.64%;
  /* background-image: url("http://localhost:3000/static/media/witch.03b98627.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* margin: 0 -0.8rem; */
}

.evidenceDate {
  background-color: white;
  position: absolute;
  bottom: 0.3rem;
  /* left: 0.1rem; */
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.7rem;
  border-radius: 0.5rem;
  /* border: 1px solid black; */
  box-shadow: black 1px 1px 3px 1px;
}

.evidenceModal img {
  margin: 0 auto;
}

#root .target {
  flex: 1 1 0;
  text-align: left;
  flex-direction: row;
}

.needle {
  width: 2.2rem;
  height: 2.2rem;
  margin-top: -0.5rem;
  margin-right: 0.6rem;
  transform: rotate(-90deg);
  cursor: pointer;
}

.recentWitchingItem {
  margin: 2.5rem 0;
}

#root .recentWitchingsPlaceholder {
  margin: 0 auto;
}

.tables {
  margin-top: 1rem;
}

.modalText {
  font-size: 1.3rem;
}

.statTable {
  font-size: 1.5rem !important;
}

#root .myLabel {
  margin-right: 1.5rem;
  background-color: #b72b06 !important;
  border-color: #b72b06 !important;
  top: -0.3rem;
}
#root .menuHomeIcon {
  margin: 0.7rem;
}

#root .profileHeader {
  font-family: "Semplicita Pro";
  font-size: 4rem;
  margin: 4rem 0;
}

#root .profileHeader img {
  height: 5rem;
  width: auto;
  margin-top: -1rem;
  margin-right: 1.2rem;
}

#root .profilePage .recentWitchings {
  margin-bottom: 3.5rem;
}

#root .profilePage .tables {
  margin-top: 2rem;
}

#root .tableFooter {
  background-color: #282c34;
}

#root .tableFooter th {
  font-weight: bold;
}

#root .profilePage th,
#root .profilePage td {
  padding-left: 1rem;
}

#root .tablePagination {
  border: none;
}

#root .likeLabel {
  margin-left: 1.5rem;
  top: -0.3rem;
}

#root .likeLabel span {
  font-weight: bold;
  font-size: 1.1rem;
}

@media (max-width: 575px) {
  #root .logo,
  #root .logo h1 {
    font-size: 4rem;
  }

  #root .logo h1 {
    margin-top: 1rem;
  }

  #root .slogan {
    font-size: 1.5rem;
  }

  #root .recentWitchings {
    font-size: 1.5rem;
  }

  #root .playerName {
    font-size: 1.5rem;
    line-height: 3rem;
  }

  #root .needle {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 0.5rem;
    /* margin-right: 0.6rem; */
  }
}

.maintananceContainer {
  margin-top: 10rem;
  text-align: center;
}

.maintananceContainer h1 {
  color: rgb(192, 192, 192);
  margin-top: 5rem;
  font-size: 3rem;
}

.maintananceContainer .cauldron {
  margin: 3.5rem auto;
}

.wantedIcon {
  position: absolute;
  top: 1.3rem;
  left: -2rem;
}

#root .bountyIcon {
  margin-bottom: -0.125rem;
  margin-right: 0.2rem;
}

.bountiesMenuItem {
  font-size: 1.5rem;
}

.bountyText {
  margin-top: 1.8rem;
  font-size: 1.2rem;
  color: white;
}

#root .bountyGold {
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
  font-size: 1.8rem;
  color: white;
}

#root .bountyCard {
  position: relative;
  padding-top: 0.7rem;
  background-color: #282c34;
  box-shadow: 0 1px 3px 0 black, 0 0 0 1px black;
}

#root .bountyFooter {
  border-top: 1px solid rgba(0, 0, 0, 0.246) !important;
  color: white;
  font-size: 1.2rem;
}

#root .bountyTarget {
  margin-bottom: 1.1rem;
  font-size: 2.8em;
  font-family: "Gang Avenue";
  text-transform: uppercase;
  font-weight: normal;
}

#root .openBountiesBtn {
  margin-right: 2.5rem;
  position: relative;
}

#root .bountiesGroup {
  margin-top: 0;
}

#root .bountiesPage {
  padding-top: 1rem;
}

#root .bountyImagePlaceholder {
  margin: 2rem 0;
}

#root .deleteBounty {
  position: absolute;
  top: -1rem;
  right: -1rem;
}

#root .claimedBountiesBadge {
  position: absolute;
  top: -0.7rem;
  right: -0.7rem;
}

.confirmModalContainer {
  text-align: center;
}

.bountyClaimedFooter {
  font-size: 1.5rem;
  margin: 0.5rem;
  color: gray;
}

.dk {
  color: #c41e3a;
}

.dh {
  color: #a330c9;
}

.druid {
  color: #ff7c0a;
}

.hunter {
  color: #aad372;
}

.mage {
  color: #3fc7eb;
}
.monk {
  color: #00ff98;
}
.paladin {
  color: #f48cba;
}
.priest {
  color: #ffffff;
}
.rogue {
  color: #fff468;
}
.shaman {
  color: #0070dd;
}
.warlock {
  color: #8788ee;
}
.warrior {
  color: #c69b6d;
}
